<template>
  <vue-final-modal
    class="modal-register-cancel"
    :overlay-transition="{ mode: 'in-out', duration: 250 }"
    :content-transition="{ mode: 'in-out', duration: 250 }"
  >
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'title') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeAllModals" />
      </div>
    </div>

    <div class="vfm__body">
      <div class="bonus">
        <atomic-image :src="getContent(props.currentLocaleData, props.defaultLocaleData, 'bonusImage')" />
        <div class="bonus-text" v-html="bonusContent" />
      </div>

      <div class="actions">
        <button-base type="primary" size="md" @click="closeModal('sign-up-cancel')">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'backButton') }}
        </button-base>

        <button-base type="outlined" size="xs" @click="signUpClose">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'closeButton') }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['cancelRegistration']>;
    defaultLocaleData: Maybe<CIModalsContent['cancelRegistration']>;
  }>();

  const { closeModal, closeAllModals } = useModalStore();
  const { getContent, replaceContent, localizePath } = useProjectMethods();
  const router = useRouter();

  const signUpClose = async () => {
    await closeAllModals();
    await router.push(localizePath('/'));
  };

  const bonusContent = getContent(props.currentLocaleData, props.defaultLocaleData, 'bonusText')
    ? replaceContent(getContent(props.currentLocaleData, props.defaultLocaleData, 'bonusText'), '*')
    : '';
</script>

<style src="~/assets/styles/components/modal/sign-up-cancel.scss" lang="scss" />
